import PaginationData from '../../interfaces/PaginationInterface';
import CustomButton from './CustomButton';
import GoBackIcon from '../assets/pagination-go-back.svg';
import GoNextIcon from '../assets/pagination-go-next.svg';

import './CustomPagination.css';
import { Dispatch, SetStateAction } from 'react';
interface CustomPaginationProps {
  currentPage: number;
  pages: number;
  totalItems: number;
  handlePageChange: Dispatch<SetStateAction<number>> | ((newPage: number) => void);
  pageSize: number;
}

function CustomPagination({ currentPage, pages, handlePageChange, totalItems, pageSize }: CustomPaginationProps) {

  const { startItem, endItem } = calculateItemsRange({ totalItems, pageSize, currentPage });

  const goToPrevPage = () => {
    handlePageChange(currentPage - 1);
  };

  const goToNextPage = () => {
    handlePageChange(currentPage + 1);
  };

  return (
    <div className="pagination-container">
      <CustomButton 
        className="pagination-item"
        sx={{visibility: currentPage > 1 ? 'visible' : 'hidden'}}
        variant="clear" 
        buttonAction={goToPrevPage} 
        icon={<img alt="pagination go back icon" src={GoBackIcon} />}
        iconPosition="start"/> 
      <p className="pagination-item" data-testid="paginated-start-end-item">{startItem}-{endItem} of {totalItems}</p>
      <CustomButton 
        className="pagination-item"
        sx={{visibility: currentPage < pages ? 'visible' : 'hidden'}}
        variant="clear" 
        buttonAction={goToNextPage} 
        icon={<img alt="pagination go next icon" src={GoNextIcon} />}
        iconPosition="start"/>
    </div>
  );
}

const calculateItemsRange = ({ totalItems, pageSize, currentPage }: PaginationData) => {
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  return { startItem, endItem };
};

export default CustomPagination;
export { calculateItemsRange };