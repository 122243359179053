import { getTranscriptsSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE } from '../../../constants';
import { withData } from '../../reusable/withData';
import { TranscriptsSummary } from '../../../interfaces/DataQcInterface';
import DataQcCardContentWithTable from '../DataQcCardContentWithTable';

const columns = [
  {key: 'name', className: ''},
  {key: 'transcript_count', className: 'align-right'}
];
interface TranscriptsContentProps {
  data: TranscriptsSummary;
}

function TranscriptsContent({data}: TranscriptsContentProps) {

  const {total_num_transcripts, schools} = data;

  return (
    <div className="transcripts-content">
      <div className="students-schools-count">
        <p><span>{total_num_transcripts}</span> students</p>
        <p><span>{schools.length}</span> schools</p>
      </div>
      <DataQcCardContentWithTable 
        schools={schools}
        columns={columns}
        currentPage={1}
        handlePageChange={() => {}}
        totalItems={data.schools.length} 
        pageSize={ DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE}/>
    </div>
  );
}

const TranscriptsContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TranscriptsContent,
    fetchData: (eoaId: string, access_token: string) => getTranscriptsSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching transcripts summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TranscriptsContentWithData;