import { MatchingSummary } from '../../../interfaces/DataQcInterface';
import { getMatchingSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';
import SuccessIcon from '../../assets/data-upload-success.svg';
import XMarkIcon from '../../assets/data-upload-x-mark.svg';

import './MatchingContent.css';

interface MatchingContentProps {
  data: MatchingSummary;
}

const descriptions: { [key: string]: string } = {
  students_matched_percentage: ' of students matched across demographics, transcripts, and test scores',
  transcript_courses_matched_percentage: ' of transcript courses matched to course catalog courses',
  test_scores_matched_to_students_percentage: ' of test scores matched to students',
  cte_courses_matched_percentage: ' of CTE courses matched to course catalog courses'
};

function MatchingContent({data}: MatchingContentProps) {

  //to be refactored, when thresholds are defined
  const renderStatusIcons = (percentage: number) => {
    if (percentage > 80) return SuccessIcon;
    return XMarkIcon;
  };

  return (
    <div className="matching-content" data-testid="matching-content">
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className="matching-status-info">
          <img className="icon-size" src={renderStatusIcons(value)} alt="matching status"/>
          <p><span>{value}%</span>{descriptions[key]}</p>
        </div>
      ))}
    </div>
  );
}

const MatchingContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: MatchingContent,
    fetchData: (eoaId: string, access_token: string) => getMatchingSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching matching summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default MatchingContentWithData;