import { getDemographicsSummary } from '../../../services/dataQcSummaryService';
import { DemographicsSummary } from '../../../interfaces/DataQcInterface';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';
import { useMemo } from 'react';

import './DemographicsContent.css';
import CustomDivider from '../../reusable/CustomDivider';
import DemographicsContentSection from './DemographicsContentSection';
import { DEMOGRAPHIC_PERCENTAGES } from '../../../constants';

interface DemographicsContentProps {
  data: DemographicsSummary;
}

const cohortDotColors = {
  [DEMOGRAPHIC_PERCENTAGES.GENDER.FEMALE]: 'var(--accent-green-main)',
  [DEMOGRAPHIC_PERCENTAGES.GENDER.MALE]: 'var(--accent-green-dark)',
  [DEMOGRAPHIC_PERCENTAGES.GENDER.OTHER]: 'var(--background-yellow-green)'
};

const totalDotColors = {
  [DEMOGRAPHIC_PERCENTAGES.GENDER.FEMALE]: 'var(--divider-50)',
  [DEMOGRAPHIC_PERCENTAGES.GENDER.MALE]: 'var(--divider-70)',
  [DEMOGRAPHIC_PERCENTAGES.GENDER.OTHER]: 'var(--background-medium-gray)'
};

const labelsMap: { [key: string]: string | string[] } = {
  [DEMOGRAPHIC_PERCENTAGES.RACE.PACIFIC_ISLANDER]: ['Pacific', 'Islander'],
  [DEMOGRAPHIC_PERCENTAGES.RACE.WHITE]: 'White',
  [DEMOGRAPHIC_PERCENTAGES.RACE.ASIAN]: 'Asian',
  [DEMOGRAPHIC_PERCENTAGES.RACE.UNKNOWN]: 'Unknown',
  [DEMOGRAPHIC_PERCENTAGES.RACE.AMERICAN_INDIAN]: ['American', 'Indian'],
  [DEMOGRAPHIC_PERCENTAGES.RACE.LATINX]: 'Latinx',
  [DEMOGRAPHIC_PERCENTAGES.RACE.TWO_OR_MORE]: 'Two or more',
  [DEMOGRAPHIC_PERCENTAGES.RACE.OTHER]: 'Other',
  [DEMOGRAPHIC_PERCENTAGES.RACE.BLACK]: 'Black'
};

const prepareChartData = (subjects: { [key: string]: number } | null, mainColor: string) => {

  if (!subjects) return { chartLabels: [], chartData: [], firstDatasetColor: '' };

  const firstDatasetColor = mainColor;

  const chartLabels: (string | string[])[] = [];
  const chartData: number[] = [];

  Object.entries(subjects).forEach(([key, value], index) => {
    const label = labelsMap[key] || key;

    chartLabels.push(label);
    // + (10 * index) is only for UAT propose, remove after that
    chartData.push(value + (10 * index));
  });

  return { chartLabels, chartData, firstDatasetColor };
};

function DemographicsContent({ data }: DemographicsContentProps) {

  //cohort section data
  const { student_count: cohort_student_count, demographic_percentages } = data.cohort;
  const { gender, race, student_with_disabilities, english_learner, low_income } = demographic_percentages;
  const { chartLabels, chartData, firstDatasetColor } = prepareChartData(race, '#145758');

  //total section data
  const { student_count, demographic_percentages: totalDemographicPercentages } = data.total;
  const { gender: totalGender, race: totalRace, student_with_disabilities: totalDisabilities, english_learner: totalEl, low_income: totalLi } = totalDemographicPercentages;
  const { chartLabels: totalLabels, chartData: totalData, firstDatasetColor: totalColor } = prepareChartData(totalRace, '#4D4D4D');

  return (
    <div className="demographics-content">
      <DemographicsContentSection
        header={{ title: 'EOA cohort', description: 'Students with complete data from each of the last four years' }}
        studentCount={cohort_student_count}
        genderData={gender}
        dotColors={cohortDotColors}
        raceChartData={{ labels: chartLabels || [], data: chartData || [], color: firstDatasetColor || '' }}
        otherData={{
          lowIncome: low_income[DEMOGRAPHIC_PERCENTAGES.INCOME.LOW_INCOME],
          disabilities: student_with_disabilities[DEMOGRAPHIC_PERCENTAGES.DISABILITIES.WITH_DISABILITIES],
          englishLearner: {
            learner: english_learner[DEMOGRAPHIC_PERCENTAGES.ENGLISH_LEARNER.LEARNER],
            native: english_learner[DEMOGRAPHIC_PERCENTAGES.ENGLISH_LEARNER.NATIVE]
          }
        }}
      />

      <CustomDivider orientation="horizontal" className="thin" />

      <DemographicsContentSection
        header={{ title: 'All students', description: ' Students with data in any of the last four years' }}
        studentCount={student_count}
        genderData={totalGender}
        dotColors={totalDotColors}
        raceChartData={{ labels: totalLabels, data: totalData, color: totalColor }}
        otherData={{
          lowIncome: totalLi[DEMOGRAPHIC_PERCENTAGES.INCOME.LOW_INCOME],
          disabilities: totalDisabilities[DEMOGRAPHIC_PERCENTAGES.DISABILITIES.WITH_DISABILITIES],
          englishLearner: {
            learner: totalEl[DEMOGRAPHIC_PERCENTAGES.ENGLISH_LEARNER.LEARNER],
            native: totalEl[DEMOGRAPHIC_PERCENTAGES.ENGLISH_LEARNER.NATIVE]
          }
        }}
        classes={['all']}
      />
    </div>
  );
}

const DemographicsContentWithData = () => {

  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = useMemo(() =>
    withData({
      WrappedComponent: DemographicsContent,
      fetchData: (eoaId: string, access_token: string) => getDemographicsSummary(eoaId, access_token, selectedSchoolFromDataQc),
      errorMessage: 'Error occured while fetching demographics summary data!',
      options: {
        dependencies: [selectedSchoolFromDataQc]
      }
    }), [selectedSchoolFromDataQc]);

  return <WrappedComponent />;
};

export default DemographicsContentWithData;