import CustomDivider from '../../reusable/CustomDivider';
import LollipopChart from '../../reusable/LollipopChart';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

interface DemographicsContentSectionProps {
  header: { title: string, description: string }
  studentCount: number;
  genderData: { [key: string]: number };
  dotColors: Record<string, string>;
  raceSectionTitle?: string;
  raceChartData: { labels: (string | string[])[]; data: number[]; color: string };
  otherData: {
    lowIncome: number;
    disabilities: number;
    englishLearner: { learner: number; native: number };
  };
  classes?: string[]
}

function DemographicsContentSection({ header, studentCount, genderData, dotColors, raceSectionTitle, raceChartData, otherData, classes }: DemographicsContentSectionProps) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const classNames = classes ? classes.join(' ') : '';

  return (
    <div data-testid="students-statistics" className="demographics-content-section">
      <div className="demographics-content-section-header">
        <span>{header.title}</span>
        {header.description}
      </div>
      <div className="students-total-count"><span>{studentCount}</span><p> students qualify for EOA cohort</p></div>
      <CustomDivider orientation="horizontal" className="light" />
      <div className="demographics-content-statistics">
        <span>Gender</span>
        <div className="bars">
          {Object.entries(genderData).map(([key, value]) => (
            <div key={key} style={{ width: `${value}%` }} className={`bar-${key.toLowerCase()} ${classNames}`}></div>
          ))}
        </div>
        <div className={`bar-percentages ${isSmallScreen ? 'small-screen' : ''}`}>
          {Object.entries(genderData).map(([key, value]) => (
            <div key={key} className="legend">
              <div style={{ backgroundColor: dotColors[key as keyof typeof dotColors] }} className="color-dot"></div>
              <span>{value}%</span>
              <p className="gender capitalized"> {key.toLowerCase()}</p>
            </div>
          ))}
        </div>
      </div>
      <CustomDivider orientation="horizontal" className="light" />
      <div className="chart-section">
        <span className="chart-title">{raceSectionTitle ? raceSectionTitle : 'Race/ethnicity'}</span>
        <div className="lollipop-chart-container">
          {raceChartData.data && raceChartData.labels && (
            <LollipopChart
              dataValues={raceChartData.data}
              labels={raceChartData.labels}
              secondDataset
              firstDatasetColor={raceChartData.color}
              secondDatasetColor="#E5E5E5"
              maintainAspectRatio={false}
            />
          )}
        </div>
      </div>

      {/* OTHER DATA */}
      <div className="demographics-content-statistics other-data">
        {/* SOCIO-DIS SECTION */}
        <div className={`socioeconomic-disability-status ${isSmallScreen ? 'small-screen' : ''}`}>
          {/* SOCIOECONOMIC */}
          <div className="socioeconomic-status">
            <div className="header">
              <span>Socioeconomic status</span>
              <div className="line"></div>
            </div>
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.lowIncome}%</p>
              <span>Low income</span>
            </div>
          </div>
          {/* DISABILITIES */}
          <div className="disability-status">
            <div className="header">
              <span>Disability status</span>
              <div className="line"></div>
            </div>
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.disabilities}%</p>
              <span>Students with disabilities</span>
            </div>
          </div>
        </div>
        {/* ELL */}
        <div className="ell-status">
          <div className="header">
            <span>ELL status</span>
            <div className="line"></div>
          </div>
          <div className={`el-native-status ${isSmallScreen ? 'small-screen' : ''}`}>
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.englishLearner.learner}%</p>
              <span>English learners</span>
            </div>
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.englishLearner.native}%</p>
              <span>Native/Fluent</span>
            </div>
            {/* for test purposes only - to check the layout */}
            <div className={`cell ${classNames}`}>
              <p className="cell-value">{otherData.englishLearner.native}%</p>
              <span>Native/Fluent</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemographicsContentSection;