import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import useSchools from '../hooks/useSchools';
import Loader from '../reusable/Loader';
import PageHeader from '../reusable/PageHeader';
import { NAVIGATION_LINKS_DATA } from '../../constants';
import SelectDropdown from '../reusable/SelectDropdown';

import './DataQcLayout.css';

const pageDescription = 'Review uploaded district data and make sure it\'s accurate.';

function DataQcLayout() {
  const [contextData, setContextData] = useState({ selectedSchoolFromDataQc: '' });

  const { schools: { isPending, error, data: schools } } = useSchools();

  const handleChange = (event: SelectChangeEvent) => {
    const {value} = event.target;

    if (value === 'All schools') setContextData({selectedSchoolFromDataQc: ''});
    else setContextData({ selectedSchoolFromDataQc: value });
  };

  return (
    <>
      <PageHeader title={NAVIGATION_LINKS_DATA.DATA_QC.label} pageDescription={pageDescription} />
      <div className="select-school-bar">
          Show data for
        <SelectDropdown
          label="School"
          selectedValue={
            contextData.selectedSchoolFromDataQc
              ? contextData.selectedSchoolFromDataQc
              : 'All schools'
          }
          handleChange={handleChange}
          items={schools ? [
            { label: 'All schools', value: 'All schools' },
            ...schools.map((school) => ({
              label: school.name,
              value: school.id
            }))
          ] : []}
          isClearable={false}
          selectSxProps={{'&.MuiInputBase-root': {marginTop: 'var(--constant-spacing-8)'}}}
        />
        {isPending ? <Loader /> : null}
        {error ? <p>Missing schools data!</p> : null}
      </div>
    
      <div className="main-content">
        <Outlet context={contextData} />
      </div>
    </>
  );
}

export default DataQcLayout;